<template>
	<div class="">
		<div class="bg-blue-900">
			
			<!-- mobile -->
			<div class="text-white houses_blue relative overflow-hidden  md:hidden">
				<div class="clouds w-full absolute" style="z-index: 1;" >
					<div 
						class="cloud_blue" 
						id="cloud_1"
						:style="`animation: moveCloud_1 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_2"
						:style="`animation: moveCloud_2 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_3"
						:style="`animation: moveCloud_3 20s linear infinite; animation-delay: -10s;`"
					/>
				</div>
				
				<div class="w-full relative" style="z-index: 12;" >
					<div class="mx-auto container ">
						<div class="text-center px-12 ">
							<h1 class="h1">
								<div class="">
									{{$t('frontpage.heading')}}
								</div>
							</h1>
							
							<div class="mt-8">
								{{$t('frontpage.intro')}}
							</div>
							
							<router-link 
								:to="{ name: 'getStarted'}"
								class="mt-6 bg-white border border-white text-blue-900 px-8 py-2 rounded-full leading-loose hover:bg-blue-100 hover:text-blue-900 my-12 inline-block">
								
								{{$t('frontpage.getStarted')}}
							</router-link>
						</div>
						<div class="flex items-end px-6">
							<img 
								src="/images/frontpage/screen-and-mobile.png"
								class=""
							/>
						</div>
						
					</div>
				</div>
				
			</div>
			
			
			
			
			<!-- larger than mobile -->
			<!-- <div 
				class="text-white houses_blue relative overflow-hidden hidden md:block "
				style="height: 70vh; max-height: 50rem;">
				
				<div class="clouds w-full absolute" >
					<div 
						class="cloud_blue" 
						id="cloud_1"
						:style="`animation: moveCloud_1 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_2"
						:style="`animation: moveCloud_2 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_3"
						:style="`animation: moveCloud_3 20s linear infinite; animation-delay: -10s;`"
					/>
				</div>
				
				<div class="w-full absolute bottom-0 hidden md:block">
					<div class="mx-auto container flex">
						<div class="w-1/3 flex flex-col justify-center pb-12">
							<div class="">
								<h1 class="h1">
									<div class="">
										{{$t('frontpage.heading')}}
									</div>
								</h1>
								
								<div class="mt-8">
									{{$t('frontpage.intro')}}
								</div>
								
								<router-link 
									:to="{ name: 'getStarted'}"
									class="inline-block mt-10 bg-white border border-white text-blue-900 px-8 py-2 rounded-full leading-loose hover:bg-blue-100 hover:text-blue-900 mr-4">
									
									{{$t('frontpage.getStarted')}}
								</router-link>
							</div>
						</div>
						<div class="w-2/3 flex items-end">
							<img 
								src="/images/frontpage/screen-and-mobile.png"
							/>
						</div>
						
					</div>
				</div>
				
			</div>
			-->
			
			
			
			
			<div 
				class="text-white houses_blue relative overflow-hidden hidden md:block ">
				<!-- style="height: 70vh; max-height: 50rem;"> -->
				
				<div class="clouds w-full absolute" style="z-index: 1;" >
					<div 
						class="cloud_blue" 
						id="cloud_1"
						:style="`animation: moveCloud_1 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_2"
						:style="`animation: moveCloud_2 20s linear infinite;`"
					/>
					
					<div 
						class="cloud_blue" 
						id="cloud_3"
						:style="`animation: moveCloud_3 20s linear infinite; animation-delay: -10s;`"
					/>
				</div>
				
				<div class="relative" style="z-index: 2;">
					<div class="mx-auto container flex">
						<div class="w-1/3 flex flex-col justify-center pb-12">
							<div class="">
								<h1 class="h1">
									<div class="">
										{{$t('frontpage.heading')}}
									</div>
								</h1>
								
								<div class="mt-8">
									{{$t('frontpage.intro')}}
								</div>
								
								<router-link 
									:to="{ name: 'getStarted'}"
									class="inline-block mt-10 bg-white border border-white text-blue-900 px-8 py-2 rounded-full leading-loose hover:bg-blue-100 hover:text-blue-900 mr-4">
									
									{{$t('frontpage.getStarted')}}
								</router-link>
							</div>
						</div>
						<div 
							class="w-2/3 flex items-end"
							style="max-height: 50rem;">
							
							<img 
								src="/images/frontpage/screen-and-mobile.png"
								class="h-full "
							/>
						</div>
						
					</div>
				</div>
				<!-- <div class="w-full absolute bottom-0 hidden md:block">
					<div class="mx-auto container flex">
						<div class="w-1/3 flex flex-col justify-center pb-12">
							<div class="">
								<h1 class="h1">
									<div class="">
										{{$t('frontpage.heading')}}
									</div>
								</h1>
								
								<div class="mt-8">
									{{$t('frontpage.intro')}}
								</div>
								
								<router-link 
									:to="{ name: 'getStarted'}"
									class="inline-block mt-10 bg-white border border-white text-blue-900 px-8 py-2 rounded-full leading-loose hover:bg-blue-100 hover:text-blue-900 mr-4">
									
									{{$t('frontpage.getStarted')}}
								</router-link>
							</div>
						</div>
						<div class="w-2/3 flex items-end">
							<img 
								src="/images/frontpage/screen-and-mobile.png"
							/>
						</div>
						
					</div>
				</div> -->
				
			</div>
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
		</div>
		
		
		
		<div class="bg-white  md:hidden grid grid-cols-3 gap-6 p-6">
			<img 
				v-for="vendor in ['samsung','lg','rpi', 'zebra','android','iphone', ]"
				:key="'vendor_'+vendor"
				:src="'./vendors/'+vendor+'.svg'"
				class="w-24 h-8 object-fit-contain opacity-25"
			/>
		</div>
		
		<div class="bg-white items-center justify-center p-12 space-x-12 hidden md:flex ">
			<img 
				v-for="vendor in ['samsung','lg','rpi', 'zebra','android','iphone', ]"
				:key="'vendor_'+vendor"
				:src="'./vendors/'+vendor+'.svg'"
				class="w-32 h-8 object-fit-contain opacity-25"
			/>
		</div>
		
		<div class="bg-gray-200 p-6 ">
			<div 
				v-for="(usp, uspIndex) in usps"
				:key="usp.code"
				class="mx-auto container">
				
				<div 
					v-if="usp.image"
					class="md:grid grid-cols-2 gap-16 py-24 ">
					
					<div 
						v-if="usp.align == 'left' && usp.image"
						class="flex justify-end">
						
						<img 
							:src="getUspImage(usp)" 
							class="md:max-w-4xl py-6 md:p-0"
						/> 
					</div>
					<div class="flex flex-col justify-center">
						<div>
							<h3 class="h2 text-blue-900">{{ $t('frontpage.usps.'+usp.code+'.heading') }}</h3>
							<p class="text-gray-600 whitespace-pre-wrap">{{ $t('frontpage.usps.'+usp.code+'.text') }}</p>
						</div>
					</div>
					<div v-if="usp.align == 'right' && usp.image">
						<img 
							:src="getUspImage(usp)" 
							class="md:max-w-5xl py-6 md:p-0"
						/> 
					</div>
				</div>
				<div 
					v-else
					class="text-center py-16 ">
					
					<div class="flex flex-col justify-center">
						<div>
							<h3 class="h2 text-blue-900">{{ $t('frontpage.usps.'+usp.code+'.heading') }}</h3>
							<p class="text-gray-600 whitespace-pre-wrap">{{ $t('frontpage.usps.'+usp.code+'.text') }}</p>
						</div>
					</div>
				</div>
				
			</div>
			
			
			
			<!-- <div 
				:key="'industryFrontpageLink_'+industry.routeName"
				class="bg-white p-8 rounded-lg flex-1 ">
				
				<h3 class="h3 text-blue-900">{{$t( 'industries.'+industry.code+'.name')}}</h3>
				<p class="text-gray-600">{{$t( 'industries.'+industry.code+'.introBlock')}}</p>
				
				<router-link
					:to="{ name: industry.routeName}"
					class="bg-gray-200 text-blue-900 px-8 py-2 rounded-full inline-block mt-2 hover:bg-blue-900 hover:text-white">
					
					Read more
				</router-link>
			</div> -->
			
		</div>
		
		
		
		
		
		
		
		
		
		
		
		
		<div class="mx-auto container">
			<SectionPoints
				:items="sections.general" 
				:rowReverse="false"
				class="my-32"
				:showImages="true"
			/>
		</div>
		
		
		
		
		
		<!-- <div class="overflow-hidden my-24 bg-gray-100 py-12">
			<div class="max-w-2xl mx-auto container text-center py-4">
				<h2 class="h1 text-blue-900 leading-tight">{{$t('product.parts.heading')}}</h2>
				<p class="inline-block mt-4">
					{{$t('product.parts.intro')}}
				</p>
			</div>
		</div> -->
		
		<div class="">
			<div class="mx-auto container">
				<SpeakToExpert />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			usps: [
				{
					code:  'overview',
					image: 'images/frontpage/usps/overview.png',
					align: 'right',
				},
				{
					code: 'insight',
					image: 'images/frontpage/usps/insight.png',
					align: 'left',
				},
				{
					code: 'player',
					image: 'images/frontpage/usps/player.png',
					align: 'right',
				},
				{
					code: 'templates',
					image: 'images/frontpage/usps/templates.png',
					align: 'left',
				},
				{
					code: 'content',
					image: {
						nb: 'images/frontpage/usps/content_nb.png',
						en: 'images/frontpage/usps/content_en.png',
					},
					align: 'right',
				},
				{
					code: 'media',
					image: 'images/frontpage/usps/media.png',
					align: 'left',
				},
				
				
				
				
				{
					code: 'instant',
					image: 'images/frontpage/usps/instant.png',
					align: 'right',
				},
				{
					code: 'designTool',
					image: 'images/frontpage/usps/designTool.png',
					align: 'left',
				},
				{
					code: 'tech',
					align: 'right',
				},
				{
					code: 'security',
					align: 'left',
				},
				
				
			],
			
		}
	},
	methods: {
		getUspImage( usp ){
			if ( typeof usp.image === 'string' ) {
				return usp.image;
			}
			else {
				return usp.image[ this.$i18n.locale ];
			}
		}
	},
}
</script>

<style lang="scss">
	.gradient {
		background: linear-gradient(0deg, rgba(13,45,67,1) 0%, rgba(13,45,67,0) 100%);
		@apply w-full;
		@apply h-48;
		@apply absolute;
		@apply bottom-0;
	}
</style>